<template>
    <div class="main-content dashboard">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>数据看板<span>截止：{{today}}</span></BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="first-section">
            <Row :gutter="16">
                <Col span="6">
                    <div class="block">
                        <div class="block-title">回款金额</div>
                        <div class="block-value"><countTo :startVal="0" :endVal="summary.performance ? summary.performance : 0" :duration="2000"/><b>元</b></div>
                        <div class="block-update"></div>
                    </div>
                </Col>
                <Col span="6">
                    <div class="block">
                        <div class="block-title">业绩达成率</div>
                        <div class="block-value"><countTo :decimals="2" :startVal="0" :endVal="summary.achievingRate ? summary.achievingRate : 0" :duration="2000"/><b>%</b></div>
                        <div class="block-update"></div>
                    </div>
                </Col>
                <Col span="6">
                    <div class="block">
                        <div class="block-title">推荐简历</div>
                        <div class="block-value"><countTo :startVal="0" :endVal="summary.countsOfRecommendThisMonth ? summary.countsOfRecommendThisMonth : 0" :duration="2000"/><b>份</b></div>
                        <div class="block-update"><Icon type="md-arrow-up" />昨日<countTo :startVal="0" :endVal="summary.countsOfRecommendLastDay ? summary.countsOfRecommendLastDay : 0" :duration="2000"/>份</div>
                    </div>
                </Col>
                <Col span="6">
                    <div class="block">
                        <div class="block-title">新增职位</div>
                        <div class="block-value"><countTo :startVal="0" :endVal="summary.countsOfPositionThisMonth ? summary.countsOfPositionThisMonth : 0" :duration="2000"/><b>个</b></div>
                        <div class="block-update"><Icon type="md-arrow-up" />昨日<countTo :startVal="0" :endVal="summary.countsOfPositionLastDay ? summary.countsOfPositionLastDay : 0" :duration="2000"/>个</div>
                    </div>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="6">
                    <div class="block">
                        <div class="block-title">新增客户</div>
                        <div class="block-value"><countTo :startVal="0" :endVal="summary.countsOfCustomerThisMonth ? summary.countsOfCustomerThisMonth : 0" :duration="2000"/><b>个</b></div>
                        <div class="block-update"><Icon type="md-arrow-up" />昨日<countTo :startVal="0" :endVal="summary.countsOfCustomerLastDay ? summary.countsOfCustomerLastDay : 0" :duration="2000"/>个</div>
                    </div>
                </Col>
                <Col span="6">
                    <div class="block">
                        <div class="block-title">Offer人数</div>
                        <div class="block-value"><countTo :startVal="0" :endVal="summary.countsOfOfferThisMonth ? summary.countsOfOfferThisMonth : 0" :duration="2000"/><b>个</b></div>
                        <div class="block-update"><Icon type="md-arrow-up" />昨日<countTo :startVal="0" :endVal="summary.countsOfOfferLastDay ? summary.countsOfOfferLastDay : 0" :duration="2000"/>个</div>
                    </div>
                </Col>
                <Col span="6">
                    <div class="block">
                        <div class="block-title">入职人数</div>
                        <div class="block-value"><countTo :startVal="0" :endVal="summary.countsOfEntryThisMonth ? summary.countsOfEntryThisMonth : 0" :duration="2000"/><b>个</b></div>
                        <div class="block-update"><Icon type="md-arrow-up" />昨日<countTo :startVal="0" :endVal="summary.countsOfEntryLastDay ? summary.countsOfEntryLastDay : 0" :duration="2000"/>个</div>
                    </div>
                </Col>
                <Col span="6">
                    <div class="block">
                        <div class="block-title">新增商机</div>
                        <div class="block-value"><countTo :startVal="0" :endVal="summary.countsOfOppThisMonth ? summary.countsOfOppThisMonth : 0" :duration="2000"/><b>个</b></div>
                        <div class="block-update"><Icon type="md-arrow-up" />昨日<countTo :startVal="0" :endVal="summary.countsOfOppLastDay ? summary.countsOfOppLastDay : 0" :duration="2000"/>个</div>
                    </div>
                </Col>
            </Row>
        </div>
        <div class="second-section">
            <div class="block">
                <div class="block-title">业绩排行</div>
                <Tabs class="block-content" size="small" :animated="false">
                    <TabPane label="面试" name="interview">
                        <Table border stripe :columns="columns" :data="interview" :loading="loading"></Table>
                    </TabPane>
                    <TabPane label="入职" name="entry">
                        <Table border stripe :columns="columns" :data="entry"></Table>
                    </TabPane>
                    <TabPane label="销售" name="sales">
                        <Table border stripe :columns="columns" :data="sales"></Table>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    </div>
</template>
<script>
import countTo from 'vue-count-to';
import {reqGetDashboard} from "../api/admin-api";

export default {
    components: { countTo },
    data() {
        return {
            today: this.$moment().format('YYYY-MM-DD'),
            loading: false,
            summary: {},
            interview: [],
            entry: [],
            sales: [],
            columns: [
                {
                    title: '排名',
                    key: 'ranking',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        if (params.row.ranking <= 3) {
                            return h('Icon', {props: {type: 'md-trophy'}})
                        }
                        return h('span', params.row.ranking)
                    }
                },
                {
                    title: '姓名',
                    key: 'consultantName',
                    width: 150,
                },
                {
                    title: '所属团队',
                    key: 'departmentName',
                    minWidth: 200,
                },
                {
                    title: '回款金额',
                    key: 'performance',
                    width: 150,
                },
                {
                    title: '业绩更新时间',
                    key: 'updatedTime',
                    width: 180,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', this.$moment(params.row.updatedTime).format('YYYY-MM-DD HH:mm:ss'))
                    }
                },
            ]
        }
    },
    methods: {
        getDashboard() {
            this.loading = true;

            this.summary = {};
            this.interview = [];
            this.entry = [];
            this.sales = [];

            let startOfThisMonth = this.$moment().startOf('month').format('YYYY-MM-DD');
            let endOfThisMonth = this.$moment().endOf('month').format('YYYY-MM-DD');
            let startOfNextMonth = this.$moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
            let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
            let lastDay = (yesterday < startOfThisMonth ? null : yesterday);
            let today = this.$moment().format('YYYY-MM-DD');
            let params = {
                startOfThisMonth: startOfThisMonth,
                endOfThisMonth: endOfThisMonth,
                startOfNextMonth: startOfNextMonth,
                lastDay: lastDay,
                today: today
            }
            reqGetDashboard(params).then((res) => {
                this.summary = res.data.data.summary || {};
                if (res.data.data.interview) {
                    res.data.data.interview.map((item, index) => item.ranking = index + 1);
                    this.interview = res.data.data.interview;
                }
                if (res.data.data.entry) {
                    res.data.data.entry.map((item, index) => item.ranking = index + 1);
                    this.entry = res.data.data.entry;
                }
                if (res.data.data.sales) {
                    res.data.data.sales.map((item, index) => item.ranking = index + 1);
                    this.sales = res.data.data.sales;
                }
                this.loading = false;
            }).catch(() => {
                this.summary = {};
                this.interview = [];
                this.entry = [];
                this.sales = [];
                this.loading = false;
            });
        }
    },
    mounted() {
        this.getDashboard();
    }
}
</script>
